* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  scroll-padding-top: 100px;
  -webkit-user-drag: none;
}
body {
  font-family: "Comic Neue", cursive;
  background-color: var(--primary-50) !important;
}
:root {
  --primary-50: #fdf8e8;
  --primary-100: #faf2cd;
  --primary-200: #f5e6a1;
  --primary-300: #ecd96a;
  --primary-400: #dfc83c;
  --primary-500: #c5b11d;
  --primary-600: #9d8e13;
  --primary-700: #7b7214;
  --primary-800: #5f5915;
  --primary-900: #504c17;
  --primary-950: #2c2b07;
  --white: #fff;
  --black: #000;
}
a {
  text-decoration: none;
}
.bgprimary {
  background-color: var(--primary-600) !important;
}
.darkbg {
  background-color: var(--primary-950) !important;
}
.lightbg {
  background-color: var(--primary-50) !important;
}

/* Button */
.primarybtn {
  border-radius: 0;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
  border: none;
  color: var(--primary-600);
  border: 1px solid var(--primary-600);
  background-color: transparent;
}
.primarybtn:hover {
  transition: 0.3s ease-in;
  color: var(--white);
  border: 1px solid var(--primary-600);
  background-color: var(--primary-600);
}
.secondarybtn {
  background-color: var(--primary-400);
  border: 1px solid var(--primary-400);
  color: var(--white);
  border-radius: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
}
.secondarybtn:hover {
  color: var(--primary-400);
  border: 1px solid var(--primary-400);
  background-color: transparent;
  transition: 0.3s ease-in;
}
.lighbgbtn {
  background-color: var(--primary);
  border-radius: 0;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
  border: none;
  border: 1px solid var(--primary);
}
.whitebtn {
  background-color: var(--white);
  border-radius: 0;
  color: var(--primary-950);
  font-size: 16px;
  font-weight: 600;
  padding: 12px 26px;
  border: none;
}
.whitebtn:hover {
  background-color: var(--primary-400);
  color: var(--white);
  transition: 0.3s ease-in;
}

/* NAV */
/* .navMain {
  border-radius: 0 0 20px 20px;
} */
.nav-bg {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.navbar-light .navbar-toggler {
  border-color: var(--primary-200);
}
.navbar-light .navbar-toggler-icon {
  background-image: url(./assets/Images/menuIcon.svg);
}
.navbar-toggler:focus {
  box-shadow: none;
}
.nav-bg {
  padding: 8px 0;
  background: var(--white);
}
.navbar-light .navbar-nav .nav-link {
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.navbar-light .navbar-nav .nav-item.active .nav-link {
  color: var(--primary-400);
  border-bottom: 2px solid var(--primary-400);
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--primary-400);
  border-bottom: 2px solid var(--primary-400);
  font-weight: 600;
}
.self-nav .navbar-brand img {
  width: 160px;
}
.navbar-brand,.nav-link{
margin-bottom: 0;
}
.self-nav {
  padding: 12px 0px;
}
.self-nav ul.navbar-nav li p {
  margin: 0 15px;
}
.self-nav ul.navbar-nav li p.active {
  color: #0072fa !important;
  font-weight: 600;
  /* border-radius: 0px 0px 5px 5px; */
  border-bottom: 2px solid #2863d5;
}
.navbtn {
  position: absolute;
  right: 0;
}

/* Commantext */
.commantext h4 {
  color: var(--primary-950);
  font-size: 40px;
  font-weight: 600;
}
.commantext h4 span {
  color: var(--primary-600);
}
.commantext p {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}
.comman_space {
  padding: 80px 0;
}
.commanOther p a{
  color: var(--primary-400);
}
/* Commantext */

/* HEROSEC */
.blackbg {
  background-color: var(--white);
}
.hero-sec {
  padding: 100px 0 50px;
}
.hero-sec h1 {
  color: #000013;
  font-size: 50px;
  letter-spacing: 2px;
  font-weight: 700;
}
.hero-sec h1 span {
  color: var(--primary-600);
}
.hero-sec h4 {
  font-size: 16px;
  color: var(--primary-900);
  background-color: var(--primary-100);
  border-radius: 0;
  font-weight: 700;
  padding: 4px 12px;
  display: inline-block;
}
.hero-sec h4 span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--primary-900);
  display: inline-block;
  margin-right: 5px;
}
.heroimg img {
  mix-blend-mode: darken;
  width: 400px;
}
/* HEROSEC */

/* GETAPP */
.get_app {
  padding: 40px 0;
  /* border-radius: 20px; */
  background-color: var(--primary-200);
  position: relative;
  z-index: 1;
}
.get_app::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-300);
  clip-path: polygon(100% 100%, 0 0, 0 100%);
  z-index: -1;
}
.get_app h4 {
  color: var(--primary-950);
  font-size: 28px;
  font-weight: 700;
}
.get_app p {
  color: var(--primary-900);
  font-size: 18px;
  margin-bottom: 0;
}
/* GETAPP */

/*  START */
.start_withmain {
  position: relative;
  z-index: 1;
  background-color: var(--primary-200);
}
.start_withmain::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-300);
  /* clip-path: circle(49.4% at 84% 12%); */
  clip-path: polygon(70% 0, 100% 0, 30% 100%, 0% 100%);
  z-index: -1;
}
.start_withboxmain {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;
}
.start_withbox {
  display: flex;
  flex-direction: column;
  gap: 18px;
  /* align-items: center; */
  border-radius: 0;
  padding-bottom: 20px;
  background-color: var(--primary-50);
  padding: 10px;
}
.start_withicon img {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--primary-400);
}
.start_withbox h5 {
  color: var(--primary-950);
  font-size: 24px;
  font-weight: 700;
}
.start_withbox p {
  color: var(--primary-900);
  font-size: 18px;
  margin-bottom: 0;
}
/*  START */

/* ABOUT */
/* Commantext */
.aboutus_text h4 {
  color: var(--secondary);
  font-size: 30px;
  font-weight: 700;
}
.aboutus_text p {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}
/* ABOUT */

/* WHYCHOOSE */
.why_chooseboxmain {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.why_choosebox {
  background-color: var(--primary-100);
  padding: 14px;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}
.why_choosebox h5 {
  color: var(--secondary);
  font-size: 24px;
  font-weight: 700;
}
.why_choosebox p {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary);
}
/* WHYCHOOSE */

/* ACCORD */
.accordsec {
  padding: 100px 0;
}
.accordsec .product-text p {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}
.selfaccord .accordion-item {
  border-radius: 0;
  margin-bottom: 10px;
  border: 1px solid var(--primary-400);
}
.selfaccord .accordion-item:first-child {
  border-top: 1px solid var(--primary-400);
}
.selfaccord .accordion-item {
  padding: 10px;
  background: transparent;
}
.selfaccord .accordion-button {
  padding: 10px;
  color: var(--black);
  background: transparent;
  font-size: 16px;
  font-weight: 500;
}
.selfaccord .accordion-button:not(.collapsed) {
  color: var(--black);
  box-shadow: none;
}
.selfaccord .accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
.accordion-flush .accordion-item:last-child {
  border: 1px solid var(--primary-400);
}
/* ACCORD */

/*  */
.contact_main {
  margin: 40px 0;
  background-color: var(--primary-200);
  border-radius: 0;
  padding: 20px;
}
.contact_text h4 {
  font-size: 28px;
  color: var(--primary-950);
  font-weight: 700;
  margin-bottom: 20px;
}
.contact_text p {
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--primary-black);
}
.contact_text p a {
  color: var(--primary-600);
}
.contact_form {
  padding: 20px 0 0;
}
.form_field {
  padding-bottom: 20px;
}
.form_field .form-control {
  padding: 10px;
}
.form_field .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: var(--primary-400);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--primary-300);
}
.contact_iconBox {
  /* background-color: var(--white); */
  border-radius: 0;
  padding: 12px;
  text-align: center;
}
.contact_iconBox p {
  font-size: 16px;
  color: var(--primary-800);
  font-weight: 700;
  margin-bottom: 0;
}
/*  */

/* Footer */
footer {
  background-color: var(--primary-950);
  color: var(--white);
}
.footer {
  padding: 20px 0;
}
.link-detail img {
  width: 160px;
}
.link-detail h6 {
  margin-bottom: 18px;
  color: var(--primary-200);
  font-size: 18px;
  font-weight: 700;
}
.link-detail p {
  margin-bottom: 10px;
  color: var(--primary-200);
  cursor: pointer;
}
.link-detail p:hover {
  color: var(--primary-600);
}
.link-detail p a {
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-200);
  font-size: 16px;
  font-weight: 400;
}
.link-detail p a:hover {
  color: var(--primary-600);
}
.footer_links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-top: 1px solid var(--primary-50);
}
.footer_links p {
  margin-bottom: 0;
  padding: 10px 0;
}
.copy-rightMain {
  background-color: var(--primary-950);
}
.copy-right {
  padding: 10px;
  border-top: 1px solid var(--primary-50);
  display: flex;
  align-items: center;
  justify-content: center;
}
.copy-right p {
  text-align: center;
  margin-bottom: 0;
  color: var(--white);
}

/* SWIPER */
.swiper {
  position: unset !important;
}
.swipermain {
  position: relative;
}
.swiper-pagination-bullet-active {
  background-color: var(--primary-950) !important;
}
.swiper-pagination {
  bottom: -30px !important;
}
/* SWIPER */

/* Footer */

.policy_text {
  padding: 100px 0 20px;
}

/* Media */

/* 1680 */
@media (max-width: 1700px) {
}

/* 1500 */
@media (max-width: 1680px) {
}

/* 1440 */
@media (max-width: 1449px) {
}

/* 1280 */
@media (max-width: 1439px) {
  .self-nav ul.navbar-nav li p {
    margin: 0 15px;
  }
  .hero-sec h1 {
    margin-bottom: 20px;
    font-size: 34px;
  }
}

/* 992 */
@media (max-width: 1279px) {
  .heroimg img {
    width: 495px;
  }
  .nav-bg {
    padding: 0px 0;
  }
  .self-nav ul.navbar-nav li p {
    margin: 0 5px;
  }
  .comman_space {
    padding: 50px 0;
  }
  .commantext h4 {
    font-size: 30px;
  }
  .aboutus_text h4 {
    font-size: 24px;
  }
  .why_choosebox h5 {
    margin-bottom: 6px;
    font-size: 24px;
  }
  .footer {
    padding: 20px 0;
  }
}

/* 768 */
@media (max-width: 991px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 8px 30px 8px 0;
  }
  .navbar-nav {
    margin-top: 20px;
  }
  .navbtn {
    position: unset;
  }
  .self-nav .navbar-nav .nav-item a {
    display: inline-block;
  }
  .hero-sec p {
    font-size: 18px;
  }
  .heroimg img {
    width: 401px;
  }
  .hero-sec {
    padding: 80px 0 30px;
  }
  .get_app h4 {
    font-size: 20px;
  }
  .get_app p {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .commantext h4 {
    font-size: 24px;
  }
  .start_withboxmain {
    grid-template-columns: repeat(2, 1fr);
  }
  .start_withbox h5 {
    font-size: 20px;
  }
  .start_withbox p {
    font-size: 16px;
  }
  .aboutus_img img {
    width: 400px;
  }
  .why_chooseboxmain {
    grid-template-columns: repeat(2, 1fr);
  }
  .why_choosebox h5 {
    font-size: 20px;
  }
  .selfaccord .accordion-item {
    padding: 4px;
    border-radius: 0;
  }
  .contact_text h4 {
    font-size: 24px;
    padding-top: 20px;
  }
  .policy_text {
    padding: 65px 0 20px;
  }
  .footer_links {
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
  }
  .link-detail {
    padding: 10px 0;
  }
  .link-detail.footer_links p {
    margin-bottom: 0;
    padding: 0;
  }
}

/* 576 */
@media (max-width: 767px) {
  .hero-sec {
    padding: 70px 0 24px;
  }
  .hero-sec h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .hero-sec h4 {
    font-size: 14px;
  }
  .hero-sec p {
    font-size: 16px;
  }
  .primarybtn,
  .secondarybtn,
  .lighbgbtn,
  .whitebtn {
    padding: 8px 16px;
    font-size: 14px;
    /* border-radius: 6px; */
  }
  .get_app {
    padding: 24px 0;
  }
  .comman_space {
    padding: 20px 0;
  }

  .aboutus_img img {
    width: 284px;
  }
  .aboutus_text h4 {
    padding-top: 20px;
    font-size: 20px;
  }
  .contact_icon img {
    padding-top: 20px;
  }
  .commantext h4 {
    font-size: 22px;
  }
  .link-detail p {
    margin-bottom: 5px;
  }
  .footer {
    padding: 14px 0;
  }
}

/* 525 */
@media (max-width: 575px) {
  /* .navMain {
    border-radius: 0 0 14px 14px;
  } */
  .navbar-nav {
    margin-top: 5px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 4px 8px;
  }
  .navbar-toggler {
    padding: 1px 7px;
  }
  .self-nav .navbar-brand img {
    width: 100px;
  }
  .commantext h4 {
    font-size: 20px;
  }
  .commantext p {
    font-size: 14px;
  }
  .start_withboxmain {
    gap: 10px;
  }
  .start_withbox h5 {
    font-size: 18px;
  }
  .start_withbox p {
    font-size: 14px;
  }
  .aboutus_text h4 {
    font-size: 18px;
  }
  .aboutus_text p {
    font-size: 14px;
  }
  .why_chooseboxmain {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  .why_choosebox h5 {
    font-size: 18px;
  }
  .why_choosebox p {
    font-size: 14px;
  }
  .selfaccord .accordion-button {
    font-size: 14px;
  }
  .contact_main {
    padding: 12px;
  }
  .contact_text h4 {
    font-size: 20px;
  }
  .contact_text p {
    font-size: 14px;
  }
  .link-detail p {
    font-size: 14px;
  }
  .link-detail p a {
    font-size: 14px;
  }
  .link-detail h6 {
    margin-bottom: 6px;
  }
  .contact_main {
    padding: 12px;
    margin: 10px 0;
  }
  .contact_text h4 {
    font-size: 20px;
  }
  .contact_text p {
    font-size: 14px;
  }
}

/* 480 */
@media (max-width: 524px) {
}

/* 425 */
@media (max-width: 479px) {
  .hero-sec h1 {
    font-size: 18px;
  }
  .hero-sec p {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .heroimg img {
    width: 300px;
  }
  .hero-sec {
    padding: 60px 0 12px;
  }
  .get_app h4 {
    font-size: 18px;
  }
  .get_app p {
    font-size: 14px;
  }
  .commantext h4 {
    font-size: 18px;
  }
  .comman_space {
    padding: 15px 0;
  }
  .why_choosebox h5 {
    font-size: 16px;
  }
  .selfaccord .accordion-body {
    font-size: 14px;
  }
  .contact_text p {
    margin-bottom: 10px;
  }
  .contact_icon img {
    padding-top: 10px;
  }
}

/* 375 */
@media (max-width: 424px) {
  .hero-sec {
    padding: 66px 0 12px;
  }
  .hero-sec h1 {
    font-size: 16px;
  }
  .start_withbox h5 {
    font-size: 16px;
  }
  .start_withbox {
    padding: 12px;
  }
  .start_withboxmain {
    grid-template-columns: repeat(1, 1fr);
  }
  .contact_main {
    padding: 12px 3px;
  }
  .contact_form {
    padding: 10px 0 0;
  }
  .form_field .form-control {
    padding: 6px;
  }
  .form_field {
    padding-bottom: 10px;
  }
}

/* 320 */
@media (max-width: 374px) {
}

/* 280 */
@media (max-width: 319px) {
}
